// src/Dashboard.js
import React from 'react';

const Dashboard = () => {
    return (
        <div className="dashboard">
            <h1>Dashboard</h1>
            <p>Welcome to the dashboard!</p>
            {/* Add more content for your dashboard here */}
        </div>
    );
};

export default Dashboard;
