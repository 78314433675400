import React from 'react';
import { AlephiumConnectButton } from '@alephium/web3-react';
import './navbar.css';
import dohLogo from './DOH_Official.png';

const Navbar = () => {
    return (
        <nav className="navbar">
            <div className="logo-container">
                <img src={dohLogo} alt="DOH Logo" className="logo" />
            </div>
            <div className="links">
    
            </div>
            <div className="wallet-connect">
                <AlephiumConnectButton />
            </div>
        </nav>
    );
};

export default Navbar;
