import React, { useState, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import './tokens.css';
import './toolbar.css'; // Import the toolbar CSS
import axios from 'axios';
import FeedbackModal from './feedbackbot';
import Toolbar from './toolbar';
import TokenDetails from './tokendetails'; // Import the TokenDetails component

// Ensure the Azure API URL is correct for your backend
const apiUrl = process.env.REACT_APP_API_URL || 'https://dohcloud.azurewebsites.net';
// Mobula API for fetching Alephium token pairs
const mobulaApiUrl = 'https://api.mobula.io/api/1/market/blockchain/pairs?blockchain=Alephium';
// GitHub token list URL
const tokenListUrl = 'https://raw.githubusercontent.com/alephium/token-list/master/tokens/mainnet.json';
// Mobula API key for authorization
const apiKey = process.env.REACT_APP_MOBULA_API_KEY;

// Fetch token list from GitHub
const fetchTokenList = async () => {
  const response = await fetch(tokenListUrl);
  if (!response.ok) throw new Error('Failed to fetch token list');
  const data = await response.json();
  return data.tokens;
};

// Fetch token pairs from Mobula API
const fetchTokenPairs = async () => {
  const response = await fetch(mobulaApiUrl, {
    headers: { Authorization: apiKey },
  });
  if (!response.ok) throw new Error('Failed to fetch token pairs');
  const data = await response.json();

  return data.data.map(entry => {
    const {
      pair: { token0, token1, volume24h, liquidity, address: pairAddress },
      price,
      price_change_5min,
      price_change_1h,
      price_change_4h,
      price_change_24h,
    } = entry;

    const marketCap = (price ?? 0) * (token1.totalSupply ?? 0);
    
    // Use token0 price (ALPH) for the ratio
    const alphPrice = token0?.price ?? 0;
    const alphTokenRatio = price !== 0 && alphPrice !== 0 ? price / alphPrice : 0;

    return {
      token1: token1.symbol,
      tokenName: token1.name,
      price: price ?? 0,
      alphTokenRatio,
      volume24h: volume24h ?? 0,
      liquidity: liquidity ?? 0,
      totalSupply: token1.totalSupply ?? 0,
      marketCap,
      price_change_5min: price_change_5min ?? 0,
      price_change_1h: price_change_1h ?? 0,
      price_change_4h: price_change_4h ?? 0,
      price_change_24h: price_change_24h ?? 0,
      pairAddress, 
      token0, // Add token0 details for ALPH info
      token1 // Add token1 details for DOH info
    };
  });
};

// Utility functions for formatting numbers and prices
const formatPrice = (price) => {
  const priceString = price.toFixed(8);
  const splitPrice = priceString.split('.');
  let leadingZerosCount = 0;
  const decimals = splitPrice[1];

  for (let i = 0; i < decimals.length; i++) {
    if (decimals[i] === '0') {
      leadingZerosCount++;
    } else {
      break;
    }
  }

  if (leadingZerosCount > 3) {
    const remainingDecimals = decimals.slice(leadingZerosCount);
    return `0.0<sup>${leadingZerosCount}</sup>${remainingDecimals}`;
  } else {
    return `$${price.toFixed(4)}`;
  }
};

const formatLargeNumber = (num) => {
  if (num >= 1e9) {
    return `${(num / 1e9).toFixed(2)}B`;
  } else if (num >= 1e6) {
    return `${(num / 1e6).toFixed(2)}M`;
  } else if (num >= 1e3) {
    return `${(num / 1e3).toFixed(2)}K`;
  } else {
    return num.toFixed(2);
  }
};

// Function to correctly render token ratio using price and live ALPH price
const renderTokenRatio = (price, alphPrice) => {
  if (!price || !alphPrice || price <= 0 || alphPrice <= 0) {
    return 'N/A';
  }

  const ratio = price / alphPrice;

  if (ratio >= 0.01) {
    return `1 = ${ratio.toFixed(2)} ALPH`;
  } else {
    return `1 = ${ratio.toFixed(6)} ALPH`;
  }
};

const Tokens = () => {
  const [tokenList, setTokenList] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [loadingFavorites, setLoadingFavorites] = useState(true);
  const [orderBy, setOrderBy] = useState('token1');
  const [order, setOrder] = useState('asc');
  const [selectedToken, setSelectedToken] = useState(null); // Track selected token for TokenDetails
  const [filters, setFilters] = useState({
    hideZeroVolume: false,
    hideSub500Liquidity: false,
  });
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    const fetchList = async () => {
      try {
        const tokens = await fetchTokenList();
        setTokenList(tokens);
      } catch (error) {
        console.error('Failed to fetch token list:', error);
      }
    };
    fetchList();
  }, []);

  const { data, error, isLoading } = useQuery({
    queryKey: ['tokenPairs'],
    queryFn: fetchTokenPairs,
  });

  const toggleFavorite = (tokenSymbol) => {
    const updatedFavorites = favorites.includes(tokenSymbol)
      ? favorites.filter(fav => fav !== tokenSymbol)
      : [...favorites, tokenSymbol];

    setFavorites(updatedFavorites);
  };

  useEffect(() => {
    if (!loadingFavorites) {
      const saveFavorites = async () => {
        try {
          await axios.post(`${apiUrl}/managefavorites`, { favorites });
        } catch (err) {
          console.error('Error saving favorites:', err);
        }
      };

      saveFavorites();
    }
  }, [favorites, loadingFavorites]);

  const handleSortRequest = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getLogoURI = (symbol) => {
    const token = tokenList.find(token => token.symbol === symbol);
    return token ? token.logoURI : '';
  };

  const handleTokenClick = (pair) => {
    console.log("Token clicked:", pair); // Debug: log the clicked token pair
    setSelectedToken(pair); // Set selected token when clicked
  };

  const filteredPairs = useMemo(() => {
    if (!data) return [];

    return [...data]
      .filter(pair => {
        if (filters.hideZeroVolume && pair.volume24h === 0) {
          return false;
        }
        if (filters.hideSub500Liquidity && pair.liquidity < 500) {
          return false;
        }
        return true;
      })
      .sort((a, b) => {
        let aValue = a[orderBy] ?? '';
        let bValue = b[orderBy] ?? '';

        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return order === 'asc' ? aValue - bValue : bValue - aValue;
        }

        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return order === 'asc' ? aValue.localeCompare(bValue) : bValue.localeCompare(aValue);
        }

        return 0;
      });
  }, [data, filters, orderBy, order]);

  return (
    <div className="table-container">
      {isLoading && <div className="text-center">Loading...</div>}
      {error && <div className="text-center text-red-500">Error: {error.message}</div>}

      {!isLoading && !error && (
        <>
          <Toolbar filters={filters} setFilters={setFilters} />

          <div className="feedback-button-container" style={{ textAlign: 'center', marginBottom: '20px' }}>
            <button className="feedback-button" onClick={() => setModalOpen(true)}>
              Send Feedback
            </button>
          </div>

          <table className="min-w-full table">
            <thead>
              <tr>
                <th onClick={() => handleSortRequest('token1')}>
                  Token {orderBy === 'token1' && (order === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSortRequest('price')}>
                  Price {orderBy === 'price' && (order === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSortRequest('alphTokenRatio')}>
                  Ratio {orderBy === 'alphTokenRatio' && (order === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSortRequest('price_change_24h')}>
                  24h {orderBy === 'price_change_24h' && (order === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSortRequest('volume24h')}>
                  Volume 24h {orderBy === 'volume24h' && (order === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSortRequest('liquidity')}>
                  Liquidity {orderBy === 'liquidity' && (order === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSortRequest('price_change_5min')}>
                  5min {orderBy === 'price_change_5min' && (order === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSortRequest('price_change_1h')}>
                  1h {orderBy === 'price_change_1h' && (order === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSortRequest('price_change_4h')}>
                  4h {orderBy === 'price_change_4h' && (order === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSortRequest('marketCap')}>
                  Market Cap {orderBy === 'marketCap' && (order === 'asc' ? '↑' : '↓')}
                </th>
                <th onClick={() => handleSortRequest('totalSupply')}>
                  Total Supply {orderBy === 'totalSupply' && (order === 'asc' ? '↑' : '↓')}
                </th>
                <th>Favorite</th>
              </tr>
            </thead>

            <tbody>
              {filteredPairs.map((pair, index) => (
                <tr
                  key={index}
                  className={selectedToken === pair ? 'selected' : ''}
                  onClick={() => handleTokenClick(pair)} // Open token details on click
                >
                  <td className="token-symbol">
                    <div className="flex items-center">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleFavorite(pair.token1.symbol);
                        }}
                        className={`favorite-btn ${favorites.includes(pair.token1.symbol) ? 'favorited' : ''}`}
                      >
                        {favorites.includes(pair.token1.symbol) ? '★' : '☆'}
                      </button>
                      <img
                        src={getLogoURI(pair.token1.symbol)}
                        alt={pair.token1.symbol}
                        className="w-6 h-6 ml-2"
                      />
                      <span className="ml-2">{pair.token1.name || pair.token1.symbol}</span>
                    </div>
                  </td>
                  <td className="cell-number" dangerouslySetInnerHTML={{ __html: formatPrice(pair.price || 0) }}></td>
                  <td className="cell-number ratio-column">{renderTokenRatio(pair.price, pair.token0.price)}</td>
                  <td className={`cell-number ${pair.price_change_24h > 0 ? 'positive-change' : 'negative-change'}`}>
                    {pair.price_change_24h?.toFixed(2) || 'N/A'}%
                  </td>
                  <td className="cell-number">{formatLargeNumber(pair.volume24h)}</td>
                  <td className="cell-number">{formatLargeNumber(pair.liquidity)}</td>
                  <td className={`cell-number ${pair.price_change_5min > 0 ? 'positive-change' : 'negative-change'}`}>
                    {pair.price_change_5min?.toFixed(2) || 'N/A'}%
                  </td>
                  <td className={`cell-number ${pair.price_change_1h > 0 ? 'positive-change' : 'negative-change'}`}>
                    {pair.price_change_1h?.toFixed(2) || 'N/A'}%
                  </td>
                  <td className={`cell-number ${pair.price_change_4h > 0 ? 'positive-change' : 'negative-change'}`}>
                    {pair.price_change_4h?.toFixed(2) || 'N/A'}</td>
                  <td className="cell-number">{formatLargeNumber(pair.marketCap)}</td>
                  <td className="cell-number">{formatLargeNumber(pair.totalSupply)}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Render the TokenDetails component when a token is clicked */}
          {selectedToken && (
            <TokenDetails token={selectedToken} onClose={() => setSelectedToken(null)} />
          )}

          <FeedbackModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
        </>
      )}
    </div>
  );
};

export default Tokens;
