import React, { useEffect, useRef } from 'react';
import { FaTrash } from 'react-icons/fa';
import NodeConsole from './nodeconsole'; // Import NodeConsole if needed

const NodeDetails = ({ selectedNode, closeDetailView, deleteNode, metrics }) => {
  const nodeDetailViewRef = useRef(null);

  // Handle click outside to close the detail view
  const handleClickOutside = (e) => {
    if (nodeDetailViewRef.current && !nodeDetailViewRef.current.contains(e.target)) {
      closeDetailView();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="node-detail-view slide-in" ref={nodeDetailViewRef}>
      <button className="close-button" onClick={closeDetailView}>
        Close
      </button>
      <h3>Node Details: {selectedNode.nodeName}</h3>
      <p>
        <strong>IP:</strong> {selectedNode.publicIP}
      </p>
      <p>
        <strong>Status:</strong> {selectedNode.status}
      </p>

      {selectedNode.publicIP && (
        <div className="api-link">
          <a
            href={`http://${selectedNode.publicIP}:12973/docs`}
            target="_blank"
            rel="noopener noreferrer"
          >
            API Swagger
          </a>
        </div>
      )}

      {/* Display the metrics here */}
      <div className="metrics">
        <h4>Node Metrics</h4>
        <p>
          <strong>CPU Usage:</strong> {metrics.cpuUsage || 'N/A'}
        </p>
        <p>
          <strong>Memory Usage:</strong> {metrics.memoryUsage || 'N/A'}
        </p>
        {/* Add more metrics as needed */}
      </div>

      <NodeConsole nodeName={selectedNode.nodeName} /> {/* Optional if logs are needed */}

      <div className="button-container">
        <button onClick={() => deleteNode(selectedNode.nodeName)} className="delete-button">
          <FaTrash />
        </button>
      </div>
    </div>
  );
};

export default NodeDetails;
