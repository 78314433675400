import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaCoins, FaServer, FaUsers } from 'react-icons/fa'; // Import FaUsers for holders
import './sidebar.css';

const Sidebar = () => {
    return (
        <div className="sidebar">
            <div className="nav-links">
                <Link to="/home" className="link">
                    <FaHome className="icon" />
                    <span className="link-text">Home</span>
                </Link>
                <Link to="/nodes" className="link">
                    <FaServer className="icon" />
                    <span className="link-text">Nodes</span>
                </Link>
                <Link to="/tokens" className="link">
                    <FaCoins className="icon" />
                    <span className="link-text">Tokens</span>
                </Link>
       
            </div>
        </div>
    );
};

export default Sidebar;
