import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useWallet } from '@alephium/web3-react';
import { useAuth } from './auth'; // Import the AuthProvider
import NodeConsole from './nodeconsole'; // Optional: use if you're displaying node logs.
import NodeStats from './nodestats'; // Import the NodeStats component
import NodeDetails from './nodedetails'; // Import the NodeDetails component
import { useNavigate } from 'react-router-dom'; // Use navigate to redirect to signup
import './nodemanager.css'; // Include any necessary styling.

const apiUrl = process.env.REACT_APP_API_URL || 'https://dohcloud.azurewebsites.net';

const NodeManager = () => {
  const [prodNodes, setProdNodes] = useState([]);
  const [testNodes, setTestNodes] = useState([]);
  const [error, setError] = useState(null);
  const [deploying, setDeploying] = useState(false);
  const [logs, setLogs] = useState([]); // Centralized logs for real-time deployment and sync status.
  const [metrics, setMetrics] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedNode, setSelectedNode] = useState(null);
  const [webSocket, setWebSocket] = useState(null);
  const [syncedStatus, setSyncedStatus] = useState({});

  const walletContext = useWallet();
  const connectionStatus = walletContext?.connectionStatus;
  const wallet = walletContext?.account;
  const connected = connectionStatus === 'connected';
  const walletAddress = wallet?.address;

  const { isApproved, hasSignedUp, checkingAuth } = useAuth(); // Get auth data from AuthProvider
  const navigate = useNavigate(); // Use navigate to redirect to signup page

  const appendLog = (message) => {
    setLogs((prevLogs) => [message, ...prevLogs]);
  };

  const fetchNodeSyncedStatus = async (nodeIp, nodeName) => {
    if (!nodeIp || !nodeName) {
      console.error('Node IP or Node Name is missing!');
      return null;
    }

    try {
      const response = await axios.get(`${apiUrl}/proxy`, {
        params: { nodeIp, nodeName },
      });
      const { synced } = response.data;
      return synced;
    } catch (err) {
      console.error('Failed to fetch synced status:', err.message);
      return null;
    }
  };

  useEffect(() => {
    if (connected && walletAddress && isApproved && hasSignedUp) {
      fetchUserNodes(walletAddress);
      const interval = setInterval(() => {
        fetchUserNodes(walletAddress);
      }, 300000); // Refresh every 5 minutes.
      return () => clearInterval(interval);
    }
  }, [connected, walletAddress, isApproved, hasSignedUp]);

  const fetchUserNodes = async (walletAddress) => {
    try {
      const response = await axios.get(`${apiUrl}/getusernodes`, {
        params: { walletAddress },
      });
      const nodes = response.data;
      setProdNodes(nodes.filter((node) => node.type === 'prod'));
      setTestNodes(nodes.filter((node) => node.type === 'test'));
      setError(null);
    } catch (err) {
      console.error('Error fetching user nodes:', err);
      setError('Failed to fetch user nodes. Please try reconnecting your wallet.');
    }
  };

  const fetchNodeMetrics = async (nodeName) => {
    try {
      const response = await axios.get(`${apiUrl}/getnodemetrics`, {
        params: { nodeName },
      });
      setMetrics(response.data);
      appendLog(`Metrics for ${nodeName} fetched successfully`);
    } catch (err) {
      appendLog(`Failed to fetch metrics for ${nodeName}: ${err.message}`);
      setMetrics({});
    }
  };

  const handleNodeClick = async (node) => {
    setSelectedNode(node);
    setLogs([]); // Clear logs when switching nodes.

    try {
      const status = await fetchNodeSyncedStatus(node.publicIP, node.nodeName);
      setSyncedStatus((prevStatus) => ({
        ...prevStatus,
        [node.nodeName]: status,
      }));

      if (webSocket) {
        webSocket.close();
        setWebSocket(null); // Clear the WebSocket state after closing
      }

      const wsResponse = await axios.get(`${apiUrl}/generateWebSocketUrl`, {
        params: { nodeName: node.nodeName },
      });
      const webSocketUrl = wsResponse.data;

      const ws = new WebSocket(webSocketUrl);
      ws.onopen = () => {
        console.log('WebSocket connection opened');
      };

      ws.onmessage = (message) => appendLog(message.data);

      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
        ws.close();
      };

      ws.onclose = (event) => {
        console.log('WebSocket closed:', event.reason);
      };

      setWebSocket(ws);
      fetchNodeMetrics(node.nodeName);
    } catch (err) {
      setError('Failed to fetch node details or connect to logs');
      console.error(err.message);
    }
  };

  useEffect(() => {
    return () => {
      if (webSocket) {
        webSocket.close();
        setWebSocket(null);
      }
    };
  }, [webSocket]);

  const closeDetailView = () => {
    setSelectedNode(null);
    setLogs([]);
    setMetrics({});
    if (webSocket) {
      webSocket.close();
      setWebSocket(null);
    }
  };

  const deployNode = async (isTestNode = false) => {
    if (!connected || !walletAddress) {
      setError('Please connect your wallet first.');
      return;
    }

    setDeploying(true);
    try {
      const nodeName = prompt('Enter the DNS name for the node:');
      if (!nodeName) {
        alert('Node name is required!');
        setDeploying(false);
        return;
      }

      const imageRepo = isTestNode ? 'cyxeio/alphnode_test:v3.6.0' : 'cyxeio/alphnode:v3.5.2';
      appendLog(`Deploying node: ${nodeName}, Wallet: ${walletAddress}, ImageRepo: ${imageRepo}`);

      const response = await axios.post(`${apiUrl}/deploynode`, { nodeName, walletAddress, imageRepo }, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (response.data && response.data.logs) {
        setLogs((prevLogs) => [...prevLogs, ...response.data.logs]);
      }

      const wsResponse = await axios.get(`${apiUrl}/generateWebSocketUrl?nodeName=${nodeName}`);
      const webSocketUrl = wsResponse.data;

      if (webSocket) {
        webSocket.close();
        setWebSocket(null);
      }

      const ws = new WebSocket(webSocketUrl);
      setWebSocket(ws);
      ws.onmessage = (message) => appendLog(message.data);
      ws.onerror = (error) => appendLog('WebSocket error. Please check the connection.');
      ws.onclose = () => appendLog('WebSocket connection closed');

      fetchUserNodes(walletAddress);
    } catch (err) {
      appendLog(`Failed to deploy node: ${err.message}`);
    } finally {
      setDeploying(false);
    }
  };

  const startNode = async (nodeName) => {
    try {
      appendLog(`Starting node: ${nodeName}`);
      await axios.post(`${apiUrl}/startnode/${nodeName}`);
      fetchUserNodes(walletAddress);
    } catch (err) {
      appendLog(`Failed to start node: ${err.message}`);
    }
  };

  const shutdownNode = async (nodeName) => {
    try {
      appendLog(`Shutting down node: ${nodeName}`);
      await axios.post(`${apiUrl}/shutdownnode/${nodeName}`);
      fetchUserNodes(walletAddress);
    } catch (err) {
      appendLog(`Failed to shutdown node: ${err.message}`);
    }
  };

  const deleteNode = async (nodeName) => {
    try {
      if (window.confirm(`Are you sure you want to delete node "${nodeName}"?`)) {
        appendLog(`Deleting node: ${nodeName}`);
        await axios.delete(`${apiUrl}/deletenode`, { params: { nodeName } });
        fetchUserNodes(walletAddress);
      }
    } catch (err) {
      appendLog(`Failed to delete node: ${err.message}`);
    }
  };

  const filteredNodes = prodNodes.concat(testNodes).filter((node) =>
    node.nodeName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (!connected) {
    return <p>Please connect your wallet to check node approval status.</p>;
  }

  if (checkingAuth) {
    return <p>Checking signup and approval status...</p>;
  }

  if (!hasSignedUp) {
    return (
      <div>
        <p>You haven't signed up yet for the beta. Please <button onClick={() => navigate('/home')}>Sign up here</button>.</p>
      </div>
    );
  }

  if (hasSignedUp && isApproved) {
    return (
      <div className="node-manager-container">
        <h2 className="section-title">Alephium Nodes</h2>
        <NodeStats prodNodes={prodNodes} testNodes={testNodes} />
        {error && <p className="error-message">{error}</p>}

        <div className="search-container">
          <input
            type="text"
            placeholder="Search nodes..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="search-input"
          />
        </div>

        <div className="button-group">
          <button className="deploy-button" onClick={() => deployNode(false)} disabled={deploying}>
            {deploying ? 'Deploying Node...' : 'Deploy Node'}
          </button>
          <div className="dropdown-content">
            <button onClick={() => deployNode(false)}>Mainnet</button>
            <button onClick={() => deployNode(true)}>Testnet</button>
          </div>
        </div>

        <div className="nodes-list">
          {filteredNodes.map((node) => (
            <div
              key={node.id}
              className={`node-card ${node.type === 'prod' ? 'prod' : 'test'}`}
              onClick={() => handleNodeClick(node)}
            >
              <h3 className="node-title">{node.nodeName}</h3>
              <p className="info">{node.publicIP || 'Pending'}</p>
              <p className="info">
                <strong>Synced: </strong>
                {syncedStatus[node.nodeName] === null ? 'Loading...' : (syncedStatus[node.nodeName] ? 'Synced' : 'Not Synced')}
              </p>
            </div>
          ))}
        </div>

        <div className="logs-section">
          <h3>Global Logs</h3>
          <pre className="logs-output">{logs.join('\n')}</pre>
        </div>

        {selectedNode && (
          <NodeDetails
            selectedNode={selectedNode}
            closeDetailView={closeDetailView}
            deleteNode={deleteNode}
            metrics={metrics}
          />
        )}
      </div>
    );
  }

  return (
    <div>
      <p>You have signed up for beta and have access to tokens, but you are waiting for approval to access the Node Manager.</p>
    </div>
  );
};

export default NodeManager;
