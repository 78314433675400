import React, { useState, useEffect } from 'react';
import axios from 'axios';

const TokenDetails = ({ token, onClose }) => {
  const [transactionData, setTransactionData] = useState([]); // Ensure it's initialized as an empty array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Debugging: Log the `token` prop to check its structure
  console.log('Token passed to TokenDetails:', token);

  useEffect(() => {
    // Check if token and token.pair.address are defined
    if (!token || !token.pairAddress) {
      setError('Pair address is missing.');
      setLoading(false);
      return;
    }

    // Load TradingView widget script
    const script = document.createElement('script');
    script.src = 'https://s3.tradingview.com/tv.js';
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      new window.TradingView.widget({
        symbol: `ALPH-${token.token1.symbol}`, // Use token1 symbol for the TradingView widget
        autosize: true,
        container_id: 'tradingview_widget',
        theme: 'dark',
        style: '1',
        locale: 'en',
        toolbar_bg: '#f1f3f6',
        enable_publishing: false,
        hide_legend: true,
        save_image: false,
        studies: [''],
        withdateranges: true,
        hide_side_toolbar: false,
        allow_symbol_change: true,
        details: true,
      });
    };

    // Clean up script on unmount
    return () => {
      document.body.removeChild(script);
    };
  }, [token]);

  useEffect(() => {
    // Ensure token.pairAddress (the pair address) exists
    if (!token || !token.pairAddress) {
      setError('Pair address is missing.');
      setLoading(false);
      return;
    }

    // Fetch transaction data from the Azure Function that pulls from the SQL database
    const fetchTransactionData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/fetchpairtrades?address=${token.pairAddress}` // Use the new Azure function to fetch trades
        );
        
        const trades = response.data || []; // Ensure trades is an array
        if (!Array.isArray(trades)) {
          setError('Invalid response format from the server.');
          setLoading(false);
          return;
        }
        setTransactionData(trades);
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch transaction data from the database');
        setLoading(false);
      }
    };

    fetchTransactionData();
  }, [token]);

  return (
    <div className="token-details-modal">
      <div className="token-details-content">
        <button className="close-btn" onClick={onClose}>X</button>
        
        {/* Check if there's an error */}
        {error && <p>{error}</p>}

        {/* If there's no error, proceed with rendering the token details */}
        {!error && token && token.token1 && (
          <>
            <h2>{token.token1.name} Details</h2>

            {/* TradingView chart */}
            <div id="tradingview_widget" style={{ height: '400px' }}></div>

            {/* Token information */}
            <div className="details-section">
              <h3>Price: ${token.price || 'N/A'}</h3>
              <h3>24h Volume: {token.volume24h || 'N/A'}</h3>
              <h3>Liquidity: {token.liquidity || 'N/A'}</h3>
              <h3>Market Cap: {token.marketCap || 'N/A'}</h3>
              <h3>Price Change 24h: {token.price_change_24h?.toFixed(2) || 'N/A'}%</h3>
            </div>

            {/* Transaction Data */}
            <h3>Recent Transactions</h3>
            {loading && <p>Loading transaction data...</p>}
            {!loading && transactionData.length > 0 && (
              <table className="transaction-table">
                <thead>
                  <tr>
                    <th>Trade ID</th>
                    <th>Price</th>
                    <th>Volume</th>
                    <th>Buyer</th>
                    <th>Seller</th>
                    <th>Timestamp</th>
                  </tr>
                </thead>
                <tbody>
                  {transactionData.map((trade, index) => (
                    <tr key={index}>
                      <td>{trade.trade_id}</td>
                      <td>{trade.price}</td>
                      <td>{trade.volume}</td>
                      <td>{trade.buyer}</td>
                      <td>{trade.seller}</td>
                      <td>{new Date(trade.timestamp).toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}

            {!loading && transactionData.length === 0 && <p>No recent transactions available.</p>}
          </>
        )}
      </div>
    </div>
  );
};

export default TokenDetails;
