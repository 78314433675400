import React from 'react';
import './toolbar.css'; // Toolbar CSS

const Toolbar = ({ filters, setFilters }) => {
  const handleToggleChange = (filterKey) => {
    setFilters((prev) => ({
      ...prev,
      [filterKey]: !prev[filterKey],
    }));
  };

  return (
    <div className="toolbar">
      <label className="toolbar-item">
        <input
          type="checkbox"
          checked={filters.hideZeroVolume}
          onChange={() => handleToggleChange('hideZeroVolume')}
        />
        Hide Tokens with 0 Volume
      </label>
      <label className="toolbar-item">
        <input
          type="checkbox"
          checked={filters.hideSub500Liquidity}
          onChange={() => handleToggleChange('hideSub500Liquidity')}
        />
        Hide Tokens with &lt; 500 Liquidity
      </label>
    </div>
  );
};

export default Toolbar;
