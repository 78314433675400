import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './defi.css';

const Defi = () => {
  const [tokenPrice, setTokenPrice] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchTokenPrice();
  }, []);

  const fetchTokenPrice = async () => {
    try {
      const response = await axios.get('https://api.diadata.org/v1/assetQuotation/Alephium/tgx7VNFoP9DJiFMFgXXtafQZkUvyEdDHT9ryamHJYrjq');
      setTokenPrice(response.data.price);
    } catch (err) {
      setError('Failed to fetch token price.');
    }
  };

  return (
    <div className="defi-container">
      <h2>DeFi Dashboard</h2>
      <div className="coming-soon">
        <p>Dashboards coming soon...</p>
      </div>
    </div>
  );
};

export default Defi;
