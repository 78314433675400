import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AlephiumWalletProvider } from '@alephium/web3-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'; // Import QueryClient and QueryClientProvider
import { AuthProvider } from './auth'; // Import AuthProvider for centralized authentication
import Navbar from './navbar';
import Sidebar from './sidebar';
import Footer from './footer';
import NodeManager from './nodemanager';
import Dashboard from './dashboard';
import HomePage from './homepage';
import Defi from './defi';
import Tokens from './tokens'; // Tokens component
import TokenDetails from './tokendetails'; // TokenDetails component
import Holders from './dashboard/holders'; // Import Holders component
import './app.css';

// Create a new instance of QueryClient
const queryClient = new QueryClient();

const App = () => {
  return (
    <AlephiumWalletProvider>
      {/* Wrap the application in QueryClientProvider and AuthProvider */}
      <QueryClientProvider client={queryClient}>
        <AuthProvider> {/* Wrap the entire app in AuthProvider */}
          <Router>
            <div className="app-container">
              <Navbar />
              <Sidebar />
              <div className="main-content">
                <Routes>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/defi" element={<Defi />} />
                  <Route path="/nodes" element={<NodeManager />} />
                  <Route path="/home" element={<HomePage />} />
                  <Route path="/tokens" element={<Tokens />} />
                  <Route path="/holders" element={<Holders />} /> {/* Add route for Holders */}
                  <Route path="/token-details/:tokenId" element={<TokenDetails />} /> {/* Add TokenDetails route */}
                  <Route path="/" element={<HomePage />} />
                </Routes>
              </div>
              <Footer />
            </div>
          </Router>
        </AuthProvider>
      </QueryClientProvider>
    </AlephiumWalletProvider>
  );
};

export default App;
