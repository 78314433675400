import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Marquee from 'react-marquee-slider';
import './tokenticker.css'; // Custom CSS for styling

// Fetching the token list from GitHub mainnet.json
const tokenListUrl = 'https://raw.githubusercontent.com/alephium/token-list/master/tokens/mainnet.json';

// Function to fetch token data from API
const apiUrl = 'https://api.mobula.io/api/1/market/blockchain/pairs?blockchain=Alephium';
const apiKey = process.env.REACT_APP_MOBULA_API_KEY;


const fetchTokenList = async () => {
  try {
    const response = await fetch(tokenListUrl);
    if (!response.ok) throw new Error('Failed to fetch token list');
    const data = await response.json();
    return data.tokens; // Assuming the tokens are inside the 'tokens' key
  } catch (error) {
    console.error('Error fetching token list:', error);
    return [];
  }
};

const fetchTopTokens = async () => {
  try {
    const response = await axios.get(apiUrl, {
      headers: { Authorization: apiKey },
    });
    const data = response.data.data;

    // Filter top 20 by volume24h, assuming volume24h exists in the data
    const topTokens = data
      .sort((a, b) => b.pair.volume24h - a.pair.volume24h) // Sort by volume
      .slice(0, 20); // Take top 20

    return topTokens.map((entry) => ({
      symbol: entry.pair.token1.symbol,
      price: entry.price || 0,
      volume: entry.pair.volume24h || 0,
      priceChange24h: entry.price_change_24h || 0,
      logo: '', // We'll assign the logo from the token list later
    }));
  } catch (error) {
    console.error('Failed to fetch top tokens:', error);
    return [];
  }
};

// Utility function to format prices with leading zeros and up to 2 decimals when no sub-zero count
const formatPrice = (price) => {
  const priceString = price.toFixed(8); // Format to 8 decimal places
  const splitPrice = priceString.split('.'); // Split into whole number and decimal parts

  let leadingZerosCount = 0;
  const decimals = splitPrice[1];

  // Count leading zeros after the decimal
  for (let i = 0; i < decimals.length; i++) {
    if (decimals[i] === '0') {
      leadingZerosCount++;
    } else {
      break;
    }
  }

  if (leadingZerosCount > 3) {
    // Show "0.0(small number counting zeros)" if more than 3 leading zeros
    const remainingDecimals = decimals.slice(leadingZerosCount);
    return `0.0<sup>${leadingZerosCount}</sup>${remainingDecimals}`;
  } else {
    // If no leading zeros, display only up to 2 decimal places
    return `$${price.toFixed(4)}`;
  }
};

const TokenTicker = () => {
  const [tokens, setTokens] = useState([]);
  const [tokenList, setTokenList] = useState([]);
  const [velocity, setVelocity] = useState(25); // Default velocity

  // Adjust the velocity based on screen width
  useEffect(() => {
    const updateVelocity = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 480) {
        setVelocity(10); // Slow down for very small screens (mobile)
      } else if (screenWidth < 768) {
        setVelocity(15); // Moderate speed for tablets
      } else {
        setVelocity(25); // Default speed for larger screens
      }
    };

    // Set velocity on initial load
    updateVelocity();

    // Add event listener to update velocity on window resize
    window.addEventListener('resize', updateVelocity);

    // Clean up event listener on component unmount
    return () => window.removeEventListener('resize', updateVelocity);
  }, []);

  // Fetch token list and top tokens initially
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch logos from the GitHub token list and the top 20 tokens
        const [list, topTokens] = await Promise.all([fetchTokenList(), fetchTopTokens()]);
        setTokenList(list);

        // Assign logos to top tokens by matching symbol
        const tokensWithLogos = topTokens.map((token) => {
          const matchingToken = list.find((t) => t.symbol === token.symbol);
          return {
            ...token,
            logo: matchingToken ? matchingToken.logoURI : '', // Assign the correct logoURI
          };
        });

        setTokens(tokensWithLogos);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 60000); // Refresh every 60 seconds

    return () => clearInterval(interval); // Clean up interval on unmount
  }, []);

  return (
    <div className="ticker-container">
      <Marquee velocity={velocity} resetAfterTries={200} scatterRandomly={false}>
        {tokens.map((token, index) => (
          <div key={index} className="ticker-item">
            {token.logo && (
              <img src={token.logo} alt={token.symbol} className="token-logo" />
            )}
            <span className="token-symbol">{token.symbol}</span>
            <span
              className="token-price"
              dangerouslySetInnerHTML={{ __html: formatPrice(token.price) }} // Use the formatted price
            ></span>
            <span
              className={`token-change ${
                token.priceChange24h >= 0 ? 'positive-change' : 'negative-change'
              }`}
            >
              {token.priceChange24h.toFixed(2)}%
            </span>
          </div>
        ))}
      </Marquee>
    </div>
  );
};

export default TokenTicker;
