import React, { useState } from 'react';
import axios from 'axios';
import './feedbackbot.css';
import { FaTelegramPlane } from 'react-icons/fa'; // Import Telegram icon

const FeedbackModal = ({ isOpen, onClose }) => {
  const [feedback, setFeedback] = useState('');
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  // Construct the Azure Function URL by appending /feedbackbot to the API URL
  const azureFunctionUrl = `https://dohcloud.azurewebsites.net/feedbackbot`;
  console.log('Azure Function URL:', azureFunctionUrl);

  const sendFeedback = async () => {
    if (!feedback) return;

    setLoading(true);

    try {
      // Send the feedback to your Azure Function
      await axios.post(azureFunctionUrl, { feedback });
      
      setSuccess(true);
      setFeedback(''); // Clear the feedback form
    } catch (error) {
      console.error('Error sending feedback:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    isOpen ? (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <h2 className="modal-header">Instant Feedback</h2>
          {success ? (
            <p className="thank-you-message">
              Thank you for your feedback! 🎉
              <br />
              You can view your feedback on our Telegram channel:
              <a href="https://t.me/doh_money" target="_blank" rel="noopener noreferrer">
                https://t.me/doh_money
              </a>
            </p>
          ) : (
            <>
              <textarea
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                placeholder="Type your feedback here..."
              />
              <div className="button-group">
                <button onClick={sendFeedback} disabled={loading || !feedback} className="submit-button">
                  {loading ? 'Sending...' : <FaTelegramPlane className="telegram-icon" />}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    ) : null
  );
};

export default FeedbackModal;
